import type { FC } from 'react';
import { useMemo } from 'react';
import { useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';

type AlterFormProps = {
    page: 'login' | 'register';
    onSignInClick?: () => void;
};
type PageBasedData = { [key in AlterFormProps['page']]: { title: string; buttonLabel: string; link: string } };

const AlterForm: FC<AlterFormProps> = ({ page, onSignInClick }) => {
    const router = useRouter();
    const { formatMessage } = useFormat({ name: 'account' });
    const { pathWithoutQuery } = usePath();

    const pageBasedData: PageBasedData = useMemo(() => {
        return {
            login: {
                title: formatMessage({ id: 'welcome.back', defaultMessage: 'Welcome back' }),
                buttonLabel: formatMessage({ id: 'sign.in', defaultMessage: 'Sign in' }),
                link: '/login'
            },
            register: {
                title: formatMessage({ id: 'not.member.yet', defaultMessage: 'Create an account' }),
                buttonLabel: formatMessage({ id: 'account.register', defaultMessage: 'Register' }),
                link: pathWithoutQuery === '/' ? '/register?lvp=?' : '/register'
            }
        };
    }, [pathWithoutQuery]);

    const title = useMemo(() => pageBasedData[page].title, [page, pageBasedData]);
    const buttonLabel = useMemo(() => pageBasedData[page].buttonLabel, [page, pageBasedData]);

    const handleClick = () => {
        onSignInClick?.();
        router.push(pageBasedData[page].link);
    };

    return (
        <div className="border-checkout-border border-t pt-40">
            <div className="m-auto grid max-w-screen-sm">
                <Typography className="font-heading text-20" as="h3">
                    {title}
                </Typography>
                <Button className="mt-26 py-14 text-base font-bold" variant="secondary" onClick={handleClick} data-cy="register">
                    {buttonLabel}
                </Button>
            </div>
        </div>
    );
};

export default AlterForm;
