import React from 'react';
import type { Account } from '@wilm/shared-types/account';
import LoginForm from './login-form';
import AlterForm from '../../account/account-atoms/alter-form';

export interface LoginProps {
    onLogin?: (account: Account) => void;
    onSignInClick?: () => void;
    registrationRedirectTextContent: string;
    registrationRedirectButtonText: string;
    registrationNoteContent: string;
}

const Login: React.FC<LoginProps> = ({
    onLogin,
    onSignInClick,
    registrationRedirectTextContent,
    registrationRedirectButtonText,
    registrationNoteContent
}) => {
    return (
        <>
            <div>
                <LoginForm
                    onLogin={onLogin}
                    registrationRedirectTextContent={registrationRedirectTextContent}
                    registrationRedirectButtonText={registrationRedirectButtonText}
                    registrationNoteContent={registrationNoteContent}
                />
            </div>
            <AlterForm page="register" onSignInClick={onSignInClick} />
        </>
    );
};

export default Login;
